<!--  -->
<template>
  <div class="stu">
    <el-card class="box-card">
      <div v-if="Object.keys(totalInfo).length>0">
        {{ totalInfo.course_name }}班课：
        创建人{{ totalInfo.nickname || totalInfo.realname }}、
        辅导老师{{ teachers}}、
        学习进展{{ totalInfo.student_progress }}（已学条目/课程总条目）、
        开班时间{{ totalInfo.course_start_date }}、
        结班时间{{ totalInfo.enroll_end_date }}、
        班级学生人数{{ totalInfo.student_num }}、
        已布置作业次数 {{ totalInfo.task_num }}
        <!-- 作业内题目满分数 {{ totalInfo.full_score_num }} -->
      </div>
      <div>
        <el-row :gutter="21" style="margin-top: 20px">
          <el-col :span="24">
            <diy-echarts
              class="border-base"
              v-bind="video_distribute"
            ></diy-echarts>
          </el-col>
        </el-row>
        <el-row :gutter="21" style="margin-top: 20px">
          <el-col :span="24">
            <diy-echarts
              class="border-base"
              v-bind="question_distribute"
            ></diy-echarts>
          </el-col>
        </el-row>

        <el-row :gutter="21" style="margin-top: 20px">
          <el-col :span="12">
            <diy-echarts
              class="border-base"
              v-bind="ac_distribute"
            ></diy-echarts>
          </el-col>
          <el-col :span="12">
            <diy-echarts
              class="border-base"
              v-bind="target_distribute"
            ></diy-echarts>
          </el-col>
        </el-row>
      </div>

      <div class="table" style="margin-top: 20px" v-loading="loading">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :row-key="(row) => row.id"
        >
          <el-table-column label="排名" type="index" align="center">
          </el-table-column>
          <el-table-column label="学校-姓名" align="center" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.show_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出勤率" align="center">
            <template slot-scope="scope">{{
              scope.row.sign_rate * 100 + "%"
            }}</template>
          </el-table-column>
          <el-table-column label="提交作业率" align="center" width="100">
            <template slot-scope="scope">{{ scope.row.task3_rate+ "%" }}</template>
          </el-table-column>
          <el-table-column label="提交题目率" align="center" width="100">
            <template slot-scope="scope">{{ scope.row.submit_rate+ "%" }}</template>
          </el-table-column>
          <el-table-column label="编程题目全AC率" align="center"  width="160" >
            <template slot-scope="scope">{{ scope.row.ac_rate+ "%" }}</template>
          </el-table-column>
          <el-table-column label="一次提交结果全AC率" align="center"  width="180">
            <template slot-scope="scope">{{ scope.row.one_ac_rate+ "%" }}</template>
          </el-table-column>
          <el-table-column
            v-for="(v, i) in headers"
            :key="i"
            :label="v"
            width="150"
            align="center"
          >
          <template #header >
            <div v-html="v"></div>
          </template>
            <template slot-scope="scope">
              <div v-if="scope.row.examList[i]">
                {{ scope.row.examList[i]?.grade }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="排名得分" align="center">
            <template slot-scope="scope">{{ scope.row.sort_score }}</template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class="pagination" style="margin-top: 20px">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
          :page-size="pageSize"
          :current-page="page"
        >
        </el-pagination>
      </div> -->
      <div style="font-size: 12px; color: gray; margin-top: 10px">
        <p>提交作业率 = 提交作业次数/作业次数</p>
        <p>提交题目率 = 提交题目数/作业内总题目数</p>
        <p>编程题目全AC率 = 提交结果全AC题目数/作业内编程题目总数</p>
        <p>一次提交结果全AC率 = 一次提交结果全AC题目数/作业中编程题目总数</p>

        <p>
          排名得分 =
          (出勤率*0.2+提交题目率*0.1+(编程题目全AC率)*0.2+(Σ考试成绩/Σ考试满分)*0.5)*100
        </p>

        <p>注:AC的含义是ACCEPT,即正确</p>
      </div>
    </el-card>
  </div>
</template>
    
    <script>
import DiyEcharts from "@/components/com/el/diyEcharts";
var dayjs = require("dayjs");
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    DiyEcharts,
  },
  data() {
    //这里存放数据
    return {
      loading: false,
      page: 1,
      pageSize: 100,
      total: 0,
      tableData: [],
      totalInfo: {},
      teachers: "",
      headers: [], //考试名称数组
      target_distribute: {
        name: "全部作业题目标签",
        data: [[]],
      },
      ac_distribute: {
        name: "提交结果全AC题目标签",
        data: [[]],
      },
      video_distribute: {
        name: "学生观看课程视频次数、时长",
        type: "zhu",
        options: { labels: [] },
        data: [[]],
      },
      question_distribute: {
        name: "学生题目提交次数",
        type: "zhu",
        options: { labels: [] },
        data: [[]],
      },
      courseId: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // console.log(this.$router.currentRoute.query.id,"this.$router.query")
    // return
    this.courseId = this.$router.currentRoute.query.id;
    this.echartsTotal();
    this.getList();
    this.getTotal();
  },
  //方法集合
  methods: {
    //echarts
    echartsTotal() {
      this.$http
        .get("/xapi/user.group/courseOnePieChart?course_id=" + this.courseId)
        .then((res) => {
          console.log(res, "学情统计");
          this.video_distribute.data = [res.data.video_distribute];
          this.question_distribute.data = [res.data.question_distribute];
          this.target_distribute.data = [res.data.target_distribute];
          this.ac_distribute.data = [res.data.ac_distribute];
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 统计
    getTotal() {
      this.$http
        .get("/xapi/user.group/courseOneStatictics?course_id=" + this.courseId)
        .then((res) => {
          this.totalInfo = res.data;
            this.teachers = this.totalInfo.teacher_list.map((v) => v.nickname).join("/");

          console.log(res, "统计统计");
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    getList() {
      this.$http
        .get(
          `/xapi/user.group/courseOneStudentList?page=` +
            this.page +
            "&page_size=" +
            this.pageSize +
            "&course_id=" +
            this.courseId
        )
        .then((res) => {
          console.log(res, "liebbbbb");
          this.tableData = res.data.list;
          this.headers = res.data.exam;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getList();
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
    <style  lang='less' scoped>
.stu {
  .box-card {
    margin-top: 20px;
    ::v-deep {
      .border-base {
        text-align: center;
        border-radius: 20px;
        padding: 10px;
        border: 1px solid #ccc;
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>