<template>
  <div class="echart-box" :id="id"></div>
</template>

<script>
import echarts from "echarts";

export default {
  props: {
    data:{
      type:Array,
      default:()=>{return []}
    },
    type:{
      type:String,
      default:()=>{return 'yuan';}
    },
    name:{
      type:[String,Object],
      default:()=>{return null;}
    },
    options:{
      type:Object,
      default:()=>{return {};}
    }
  },
  data(){
    return {
      id: 'echart-'+(new Date).getTime(),
      echartData: {},
      loading:false,
      myChart:null
    }
  },
  watch:{
    '$props':{
      immediate:true,
      deep:true,
      handler(){
        this.echartsInit();
      }
    }
  },
  mounted() {
    this.echartsInit();
  },
  methods:{
    echartsInit(){
      if(this.loading) return ;
      this.loading = true;
      let {labels,...otherOptions} = this.options;
      // console.log(this.options,"optionsssssssssss")
      let title = this.name instanceof Object ? {...this.name} : {
        text: this.name,
        left: 'center'
      };
      let funcName = 'echartsInit_'+(this.type || 'yuan');
      let $options = {};
      try{
        $options = this[funcName]({data:this.data,labels} ,title.text);
      }catch (e) {
        if(funcName !== 'echartsInit_yuan'){
          $options = this.echartsInit_yuan({data:this.data,labels} ,title.text);
        }
      }
      var initLoading = (callback)=>{
        this.echartsData = {...$options,...otherOptions,title,};
        var isMerge = !(this.myChart === null);
        if(!isMerge){
          this.myChart = echarts.init(this.$el)
        }
        try{
          this.myChart.setOption(JSON.parse(JSON.stringify(this.echartsData)) , {notMerge:isMerge});
        }catch (e) {
          callback && callback(this.myChart);
        }
      }
      this.$nextTick(()=>{
        initLoading();
        this.loading = false;
      })
    },
    initLabels(_labels , _data , _dataOptions){
      var dataCount = 0;
      _data = (_data || []).map(d=>{
        let data =  d.data instanceof Array ? {...(_dataOptions || {}),...d,data:[...(d.data || [])]} : {...(_dataOptions || {}), data: d || [], }
        dataCount += (data.data || []).length;
        return data;
      })
      if(_labels.length === 0){
        let labels = [];
        for (let i = 0; i < (_data || []).length; i++) {
          let $data = _data[i].data || [];
          for (let j = 0; j < $data.length; j++) {
            let _data = $data[j];
            if(labels.indexOf(_data.name) === -1){
              labels.push(_data.name);
            }
          }
        }
        _labels = [...labels];
      }else if(dataCount === 0){
        _data = [{...(_dataOptions || {}),data:[]}];
        for (let i = 0; i < _labels.length; i++) {
          _data[0].data.push({
            name:_labels[i],
            value:0,
          });
        }
      }else{
        for (let i = 0; i < _data.length; i++) {
          let $data = [..._data[i].data];
          _data[i].data = [];
          for (let j = 0; j < _labels.length; j++) {
            _data[i].data[j] = {name:_labels[j],value:0};
            let matched = /^[0-9]{1,}-[0-9]{1,}$/g.exec(_labels[j]);
            if(matched){
              let min = parseFloat(_labels[j].split('-')[0]);
              let max = parseFloat(_labels[j].split('-')[1]);
              for (let k = 0; k < $data.length; k++) {
                if(isNaN($data[k].name)){
                  break;
                }
                let val = parseFloat($data[k].name);
                if(val >= min && val < max){
                  _data[i].data[j].value += parseFloat($data[k].value);
                }
              }
            }else{
              _data[i].data[j].value = parseFloat(($data.filter(d=>d.name+'' === _labels[j]+'')[0] || {}).value || 0);
            }
          }
        }
      }
      return {labels:_labels , data:_data};
    },
    echartsInit_zhu(options , title){
      let {labels,data} = this.initLabels(options.labels || null ,  options.data || [] , { name:title,type: 'bar',label:{
        show: true,
        position: 'top'
      }})
      // data[0].label={
      //   show: true,
      //   position: 'top'
      // }
      // console.log(data,"aaaaaaaaaaaa")
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}'
        },
        xAxis: {
          type: 'category',
          data: labels
        },
        yAxis: {
          type: 'value'
        },
        series: [...data]
      };
    },
    echartsInit_yuan(options , title){
      let {data} = this.initLabels(options.labels || [] ,  options.data || [] , { name:title,
        type: 'pie',
        radius: '50%',
        roseType: options.roseType || '',
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 1
        },
        label: {
			normal: {
				show: true,
				// position: 'inner', // 数值显示在内部
				formatter: `{b} \n ({d}%)`, // 格式化数值百分比输出
			},
		},
      })
        
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          type: 'scroll',
          top: 'bottom'
        },
        series: [...data]
      };
    }
  }
}
</script>

<style scoped lang="less">
.echart-box::v-deep{
    height:300px;
    padding: 10px;
    width: calc(100% - 22px);
}
</style>